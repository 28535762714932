import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import { loginApi } from "../../apis/LoginApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { StyledButton } from "../../styles/StyledComponents";
import { production } from "../../configs/settings";

interface OtpVerificationProps {
  isOtpVerified: boolean;
  setIsOtpVerified: any;
  email: string;
  rememberMe: boolean;
  setRememberMe: any;
}

export default function OtpVerification({
  email,
  isOtpVerified,
  setIsOtpVerified,
  rememberMe,
  setRememberMe,
}: OtpVerificationProps) {
  const [verificationCode, setVerificationCode] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsResendDisabled(false);
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  async function handleVerification(event: any) {
    event.preventDefault();
    try {
      if (!verificationCode) throw new Error("Please enter verification code");
      const res: any = await loginApi.verifyOtp(email, verificationCode);
      if (!res?.success) throw new Error("Invalid verification code");
      setIsOtpVerified(true);
      setVerificationCode("");
    } catch (error: any) {
      if (!production) console.error(error);
      dispatch(openSnackbar({ message: "Verification failed: " + error.message, severity: "error" }));
    }
  }

  async function handleResendCode(event: any) {
    event.preventDefault();
    setIsResendDisabled(true);
    setCountdown(60);

    try {
      const res: any = await loginApi.generateOtpAndSendEmail({ email });
      if (!res?.success) throw new Error("Error in sending email");
      dispatch(openSnackbar({ message: "Otp Resent successfully", severity: "success" }));

      // Start countdown
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error: any) {
      if (!production) console.error(error);
      dispatch(openSnackbar({ message: "Resend failed! Please try again later", severity: "error" }));
      setIsResendDisabled(false);
      setCountdown(60);
    }
  }

  let twoFactorDisabled = Cookies.get("twoFactorDisabled") === "true"; // Check if twoFactorDisabled

  return (
    <Box>
      {!isOtpVerified ? (
        <React.Fragment>
          <Typography variant="body2" color="text.secondary">
            To verify your email address, we have sent a verification code to the email address provided. The code
            expires in 30 minutes.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="verificationCode"
            label="Verification Code"
            type="number"
            id="verificationCode"
            onChange={(e) => setVerificationCode(e.target.value?.toUpperCase())}
          />
          {!twoFactorDisabled && (
            <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  onChange={() => setRememberMe(!rememberMe)}
                  checked={rememberMe}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.875rem",
                  }}
                >
                  Don't ask for verification code on future logins
                </Typography>
              }
            />
          )}
          <StyledButton variant="contained" sx={{ mt: 1, fontSize: "1rem" }} onClick={handleVerification}>
            Verify Code
          </StyledButton>
          <Button
            variant="text"
            sx={{ mt: 1, fontSize: "1rem" }}
            onClick={handleResendCode}
            disabled={isResendDisabled}
          >
            {isResendDisabled ? `Resend Code (${countdown}s)` : "Resend Code"}
          </Button>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              fontSize: "1rem",
              color: "text.secondary",
              mt: 9,
            }}
          >
            <Typography>
              If you have any questions or experience any issues, please{" "}
              <Typography component={Link} to="/contact-us" target="_blank">
                Contact Us
              </Typography>
            </Typography>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="body2" color="text.secondary">
            Verification Successful <CheckCircleIcon sx={{ color: green[500], fontSize: "1rem" }} />
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
}
