import Avatar from "@mui/material/Avatar";
import {
  Stack,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { setUserId } from "../redux/slices/user";
import { loginApi } from "../apis/LoginApi";
import { useDispatch } from "react-redux";
import WelcomeSection from "../components/common/WelcomeSection";
import OtpVerification from "../components/login/OtpVerification";
import { openSnackbar } from "../redux/slices/snackbar";
import Copyright from "../components/common/Copyright";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ForgotPasswordDialog from "../components/login/ForgotPasswordDialog";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { scratchCardApi } from "../apis/ScratchCardApi";
import WinnersList from "../components/common/WinnersList";
import { scratchCardTypeEnum, scratchCardUserStatusEnum } from "../types/scratchCardEnum";
import { SubscriptionsGiveAway } from "../components/common/SubscriptionsGiveAway";
import { StyledButton } from "../styles/StyledComponents";
import { HEADER_HEIGHT } from "../components/common/Header";
import { production } from "../configs/settings";

interface LoginProps {
  skipVerification?: boolean;
  emailReceived?: string;
  scratchCardDetails?: any;
  redirectPathAfterLogin?: string;
}

export default function Login({
  skipVerification,
  emailReceived,
  scratchCardDetails,
  redirectPathAfterLogin = "/user",
}: LoginProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const theme = useTheme();

  // let twoFactorDisabled = Cookies.get("twoFactorDisabled") === "true";
  // if (twoFactorDisabled) {
  //   skipVerification = true;
  // }
  // TODO: Temporary skip 2FAverification
  skipVerification = true;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (emailReceived) {
      setEmail(emailReceived); // Set email when emailReceived is provided
    }
  }, [emailReceived]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!email || !password) throw new Error("Email and password are required");
      // if (!validateEmail(email)) throw new Error("Please enter a valid email address");
      const res = await loginApi.verifyCredentials(email, password);
      const userId = res.user?._id;
      if (!userId) throw new Error("No user found");

      if (!skipVerification) {
        const otpResponse = await loginApi.generateOtpAndSendEmail({ email });
        if (!otpResponse) throw new Error("Error in sending email");
        setEmail(otpResponse?.email);
        dispatch(openSnackbar({ message: "Otp sent successfully", severity: "success" }));
        setIsVerified(true);
      } else {
        const res = await loginApi.signInUser(email, password);

        if (!res?.success) throw new Error("Invalid credentials");
        const userId = res?.user?._id;
        const jwtToken = res?.user?.jwtToken;
        if (!userId) throw new Error("No user found");
        if (!jwtToken) throw new Error("Authorization failed");
        dispatch(setUserId({ userId, jwtToken }));

        if (scratchCardDetails) {
          if (
            scratchCardDetails?.type === scratchCardTypeEnum.PromoCode ||
            scratchCardDetails?.type === scratchCardTypeEnum.PromoReferral
          ) {
            await scratchCardApi.claimFreePromoCode({
              ownerUserId: userId,
              scratchCardFullCode: scratchCardDetails.fullCode,
            });
            navigate(`/user?scratchCardId=${scratchCardDetails?._id}&tab=subscriptions`);
          } else {
            navigate(`/redeem-scratch-card/${scratchCardDetails?.fullCode}`);
          }
        } else {
          navigate("/user");
        }
      }
    } catch (error: any) {
      let errorMessage = "Login failed ";
      if (error?.message === "INVALID_CREDENTIALS") {
        errorMessage = "The email or password you entered is incorrect. Please try again.";
      }
      if (error?.message === "UNAUTHORIZED") {
        errorMessage = "You are not authorized to access this resource.";
      }
      dispatch(
        openSnackbar({
          message: errorMessage,
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOtpVerified) {
      loginApi
        .signInUser(email, password)
        .then((res) => {
          if (rememberMe) {
            Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
          }

          if (!res?.success) throw new Error("Invalid credentials");
          const userId = res?.user?._id;
          const jwtToken = res?.user?.jwtToken;
          if (!userId) throw new Error("No user found");
          if (!jwtToken) throw new Error("Authorization failed");
          dispatch(setUserId({ userId, jwtToken }));
          navigate(redirectPathAfterLogin || "/user");
        })
        .catch((err) => {
          dispatch(openSnackbar({ message: "Login failed ", severity: "error" }));
          if (!production) console.error(err);
          setIsOtpVerified(false);
        });
    }
  }, [isOtpVerified, dispatch, email, password, navigate, rememberMe, redirectPathAfterLogin]);

  return (
    <ThemeProvider theme={theme}>
      <Stack
        sx={{
          minHeight: {
            xs: "calc(100vh - " + HEADER_HEIGHT.xs + ")",
            sm: "calc(100vh - " + HEADER_HEIGHT.sm + ")",
          },
          flexDirection: { xs: scratchCardDetails ? "column-reverse" : "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(to bottom, #f0a725, #f05725)",
            pt: "0",
            pb: { sm: "4rem", xs: 0 },
            display: { xs: isVerified && !scratchCardDetails ? "none" : "flex", sm: "flex" },
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          {!scratchCardDetails ? (
            <WelcomeSection title="Welcome" page="login" />
          ) : scratchCardDetails.type === scratchCardTypeEnum.PromoCode ||
            scratchCardDetails.type === scratchCardTypeEnum.PromoReferral ? (
            <SubscriptionsGiveAway />
          ) : (
            <WinnersList />
          )}
        </Box>
        <Box
          component={Paper}
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: { sm: "center", xs: "flex-start" },
            mt: { xs: scratchCardDetails ? "1rem" : "1rem", sm: "0" },
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{
              my: { xs: 0, sm: 8 },
              mx: 4,
              pb: { xs: scratchCardDetails ? "2rem" : 0, sm: 0 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                transition: "transform 0.3s ease, opacity 0.3s ease",
                transform: !isOtpVerified ? "scale(1)" : "scale(1.2)",
                opacity: !isOtpVerified ? 1 : 0.7,
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
                {!isOtpVerified ? <LockOutlinedIcon /> : <LockOpenIcon />}
              </Avatar>
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {!isVerified ? (
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address or Username"
                  name="email"
                  autoComplete="email"
                  value={email}
                  autoFocus
                  disabled={!!emailReceived}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  sx={{ mt: 3, mb: 2, fontSize: { xs: "1rem", sm: "1.1rem" } }}
                >
                  {isLoading ? "Signing In..." : "Sign In"}
                </StyledButton>
                <Button onClick={() => setShowForgotPassword(true)} disabled={isLoading} sx={{ textTransform: "none" }}>
                  Forgot Password
                </Button>

                {scratchCardDetails && (
                  <Box sx={{ mt: 3, textAlign: "left" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                      New here?{" "}
                      <Link
                        onClick={() => navigate("/signup")}
                        sx={{ cursor: "pointer", color: "black", textDecoration: "underline" }}
                      >
                        Create an Account
                      </Link>
                    </Typography>
                    <Typography variant="body2" color="textPrimary" sx={{ mt: 2, fontWeight: "bold" }}>
                      Why do I need to create an account?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      You will need to create an account to claim digital subscription or prizes. Without an account we
                      have no way of giving that to you.
                    </Typography>
                    <Typography variant="body2" color="textPrimary" sx={{ mt: 2, fontWeight: "bold" }}>
                      What information is needed to create an account?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      The only information needed to create an account is your email address. From there you just need
                      to create a username and password. That’s it!
                    </Typography>
                  </Box>
                )}

                {/* <Copyright sx={{ mt: 5 }} /> */}
              </Box>
            ) : (
              <OtpVerification
                email={email}
                isOtpVerified={isOtpVerified}
                setIsOtpVerified={setIsOtpVerified}
                rememberMe={rememberMe}
                setRememberMe={setRememberMe}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <ForgotPasswordDialog open={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    </ThemeProvider>
  );
}
